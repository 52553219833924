.header {
  position: sticky;
  top: var(--padding);
  margin-bottom: calc(3 * var(--line-height-space));
}

.scroll-to-top-btn {
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: calc(2 * var(--padding));
}

.scroll-to-top-btn:before {
  content: "^";
  font-size: 1.7rem;
}
